import axios from 'axios';
import {ToastStore} from 'react-toasts';

axios.interceptors.response.use(null, error => {
    const expectedError = error.response && error.response.status === false;

    if(!expectedError){
        console.log(error);
        ToastStore.error("An unxpected error occured");
    }

    return Promise.reject(error);
});

function setJwt(jwt){
    axios.defaults.headers.authorization = 'Bearer '+jwt;
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setJwt,
    //apiEndpoint: 'http://127.0.0.1:8000/api',
    //apiEndpoint: 'https://teambarks.trangotech.dev/api'
    apiEndpoint: 'https://backend.assessmentace.com/api'
};