import React, { Component } from 'react';
import {getCurrentUser, logout} from '../services/authService';
import { getProfile } from '../services/profileService';
import Footer from './Footer';

class Profile extends Component{
    
    constructor(props){
        super(props);
    }
    state = {
        user : {},
    }

    async componentDidMount(){
        //let user =  JSON.parse(getCurrentUser());
        let user =  await getProfile();
        this.setState({user: user.data});
        console.log(user);
    }

    handleLogout(){
        logout();    
    }

    render(){
        const userData = this.state.user;
        let progressStyle = {
            '--value': userData.progress ?? 0
        }
        return(
            <>
            <header className="profile-header">
                <div className="container">
                    <div className="logout position-relative">
                        <a href="javascript:void(0)" onClick={this.handleLogout}>
                            <span className="svg-cont">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7" stroke="#FCFCFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14 15L19 10L14 5" stroke="#FCFCFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M19 10H7" stroke="#FCFCFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                Logout
                            </span>
                        </a>
                    </div>
                    <div className="text-content ">
                        <h1>Profile</h1>
                    </div>
                    <div className="img-cont">
                        <img src="/assets/img/profile.png" />
                    </div>

                </div>
            </header>
            <section className="profile">
                {/* {userData &&  */}
                    <div className="container">
                        <div className="text-cont">
                            <h2>{userData.name ?? ""}</h2>
                            <p>{userData.email ?? ""}</p>
                            <h4>{userData.role ?? ""}</h4>
                        </div>
                        <div className="box">
                            <div className="text-content">
                            
                                {/* <h3>Avg Percentage</h3> */}
                                <h3>Bonus</h3>
                                {userData.bonus_points != undefined && 
                                <div className="circle position-relative">
                                    <div className="profile-progress" role="progressbar-round"  aria-valuemax="100" style={progressStyle}>
                                        <span className="task-number">{userData.completed_percent ?? 0.00}</span>
                                        <span className="progress-text">Percent</span>
                                    </div>
                                </div>                    
                                }        
                                <span className="svg-cont">
                                    {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.57793 9.5972C9.58121 9.59376 9.58215 9.58919 9.58551 9.58583L12.9295 6.24182V4.14065C12.9295 3.98498 12.9913 3.83623 13.1011 3.72634L16.6168 0.171627C16.7839 0.0045925 17.0362 -0.046346 17.2554 0.0446323C17.4746 0.135025 17.6171 0.349052 17.6171 0.585932V2.34378H19.414C19.6508 2.34374 19.8648 2.4862 19.9552 2.70539C20.0462 2.92453 19.9959 3.17633 19.8282 3.34399L16.2735 6.89871C16.1636 7.0086 16.0148 7.07039 15.8592 7.07039H13.758L10.414 10.4144C10.4107 10.4177 10.4061 10.4187 10.4027 10.4219C10.1745 10.6397 9.82801 10.6426 9.59684 10.4219C9.35339 10.1896 9.35886 9.8267 9.57793 9.5972Z" fill="#979797"/>
                                        <path d="M9.99988 0C11.4992 0 12.9167 0.381453 14.1932 0.977285L12.3958 2.77474C11.6414 2.52388 10.8374 2.38286 9.99984 2.38286C5.7998 2.38282 2.38259 5.80007 2.38259 10.0001C2.38259 14.2002 5.7998 17.6174 9.99988 17.6174C14.2 17.6174 17.6172 14.2002 17.6172 10.0001C17.6172 9.1626 17.4761 8.35856 17.2253 7.60417L19.0228 5.80671C19.6185 7.08326 20 8.50083 20 10.0001C20 15.4928 15.4925 20.0003 9.99988 20.0003C4.50724 20.0003 -0.000272751 15.4928 -0.000272751 10.0001C-0.000272751 4.50747 4.50716 0 9.99988 0Z" fill="#979797"/>
                                        <path d="M9.99994 4.72656C10.6193 4.72656 11.2052 4.85352 11.7578 5.05051V5.75654L10.4032 7.11114C10.2701 7.09255 10.138 7.07036 9.99994 7.07036C8.38455 7.07036 7.07019 8.38472 7.07019 10.0001C7.07019 11.6155 8.38455 12.9298 9.99994 12.9298C11.6153 12.9298 12.9297 11.6155 12.9297 10.0001C12.9297 9.86197 12.9075 9.72986 12.8889 9.59685L14.2435 8.24222H14.9495C15.1465 8.79477 15.2734 9.38071 15.2734 10.0001C15.2734 12.908 12.9079 15.2736 9.99994 15.2736C7.09199 15.2736 4.7264 12.908 4.7264 10.0001C4.7264 7.09208 7.09195 4.72656 9.99994 4.72656Z" fill="#979797"/>
                                        </svg> */}
                                    <p>Avg Percentage</p>
                                </span>
                            </div>
                        </div>
                    </div>
                {/* } */}
            </section> 
            <Footer />
            </>
        );
    }
}

export default Profile;