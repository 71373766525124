import { useHistory } from "react-router-dom";
import {logout} from '../services/authService';
function Header(props){
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('user'));
    console.log("user", user);
    const goBack = () => {
        if(props.url != undefined && props.url != ""){
            console.log(props.url);
            let previous_urls = localStorage.getItem('previousUrls');
            var previousUrls = JSON.parse(previous_urls);
            previousUrls.pop(previousUrls.lenth-1);
            localStorage.setItem('previousUrls', JSON.stringify(previousUrls));
            window.location.href = props.url;
        }else{
            console.log("goback")
            history.goBack()
        }
        
    }
    const handleLogout = () =>{
        logout();    
    }
    return (
        <header>
            <div className="logout position-relative">
                <a className="mt-3" href="javascript:void(0);"><span><strong>{user.name}</strong></span></a>
                <a href="javascript:void(0)" onClick={handleLogout}>
                    <span className="svg-cont">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7" stroke="#FCFCFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M14 15L19 10L14 5" stroke="#FCFCFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M19 10H7" stroke="#FCFCFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                        Logout
                    </span>
                </a>
            </div>
            <h1>{props.title}</h1>
            {props.isBack &&
                <div className="back-btn position-relative">
                    <a type="button" className="btn btn-light" onClick={goBack}>
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 2L2 8L8 14" stroke="#323232" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Back
                    </a>
                </div>
            }
        </header>
    );
}

export default Header;