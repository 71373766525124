import React, { Component } from "react";
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import ProtectedRoute from "./Components/common/protectedRoutes";
import Login from "./Components/Login";
import Calender from "./Components/Calender";
import Profile from "./Components/Profile";
import Task from "./Components/Task";
import SubTask from "./Components/SubTask";
import Stats from "./Components/Stats";
import JobTitle from "./Components/JobTitle";
import EmployeeStats from "./Components/EmployeeStats";
import EditTask from "./Components/EditTask";
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from "./Components/ForgotPassword";
import OtpToken from "./Components/OtpToken";
import ResetPassword from "./Components/ResetPassword";
import OverallStats from "./Components/OverallStats";

class App extends Component{

  render(){
    return (
      <Router>
        <div> 
          {/*
            A <Switch> looks through all its children <Route>
            elements and renders the first one whose path
            matches the current URL. Use a <Switch> any time
            you have multiple routes, but you want only one
            of them to render at a time
          */}
          <Switch>
            <Route exact path="/"> 
              <Login /> 
            </Route>
            <Route exact path="/forgot-password"><ForgotPassword/></Route>
            <Route path="/verify-token:token?"><OtpToken/></Route>
            <Route path="/reset-password:token?"><ResetPassword/></Route>
            <ProtectedRoute path="/calender" component={Calender} /> 
            <ProtectedRoute path="/job-titles" component={JobTitle} /> 
            <ProtectedRoute path="/tasks/:id/:employee?" component={Task} /> 
            <ProtectedRoute path="/subtasks/:id" component={SubTask} /> 
            <ProtectedRoute path="/profile" component={Profile} /> 
            <ProtectedRoute path="/stats" component={Stats} /> 
            <ProtectedRoute path="/employee-stats" component={EmployeeStats} /> 
            <ProtectedRoute path="/overall-stats" component={OverallStats} /> 
            <ProtectedRoute path="/edit-tasks/:id" component={EditTask}/> 
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
